<template>
  <div class="country-selector">
    <div class="container">
      <div class="row">
        <div class="col col-12">
          <h1>Select your country</h1>
          <p class="help-text">It will be used for searches and feeds.</p>
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <input class="country-filter" type="search" v-model="filter" placeholder="Filter country list...">
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <ul class="country-list">
            <template v-for="country in countryList">
              <li 
                class="country-item" 
                :key="country.code"
                @click="updateStoreCountry(country)"
                v-if="isVisible(country)">
                <img class="country-item__img" :src="country.flag" alt="">
                <span class="country-item__name">{{country.name}}</span>
              </li>
            </template>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import iTunes from '@/services/itunes'
import countryList from '@/resources/countries.json'

const itunes = new iTunes();

export default {
  name: 'home',
  components: {
  },
  data() {
    return {
      countryList: countryList,
      filter: ''
    }
  },
  computed: {
  },
  methods: {
    updateStoreCountry(country) {
      itunes.storeCountry = country;
      this.$root.$emit('country-changed', country);
    },
    isVisible(country) {
      return this.filter.length < 2|| country.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
    }
  }
}
</script>

<style lang="scss">

.country-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.country-item {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    height: 1px;
    background: #ddd;
    bottom: 0;
    left: 15px;
  }

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 33.333%;
  }

  @media (min-width: 1200px) {
    width: 25%;
  }

  &__link {
    
  }
  &__img {
    width: 30px;
    margin-right: 5px;
  }
  &__name {
    
  }
}
.country-filter {
  display: block;
  width: 50%;
  margin: 2rem auto;
  line-height: 22px;
  border: 1px solid #ddd;
  background: #f2f2f2;
  border-radius: 8px;
  padding: 5px 10px;
  text-align: center;
}
</style>
